import "primereact/resources/themes/lara-light-indigo/theme.css";    
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import './appointmentsTable.css'
import React, { useState, useEffect,useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
// import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from 'primereact/calendar';
// import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import axios from '../../Utils/axios'
import { allAppointmentsUrl, updateAppointmentsUrl, getStatsUrl } from "../../Utils/Urls";
import { useParams } from 'react-router-dom';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';

export default function AppointmentsTable() {
    const {userEmail} = useParams()
    const {locationId} = useParams()
    const [customers, setCustomers] = useState(null);
    const [stats, setStats] = useState([]);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(true);
    const [statsLoading, setStatsLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [isMounted, setIsMounted] = useState(false)
    const toast = useRef(null);
    const [dates, setDates] = useState(null);
    const [sources] = useState(['Organic', 'Paid']);
    const [statuses] = useState(['Yes', 'No']);
    const [appointmentStatuses] = useState(['Upcoming', 'Showed', 'Rescheduled', 'Cancelled', 'No Showed']);
    const [centralObjections] = useState(['N/A', 'Financing - Interest Too High', 'Financing - Payment Too High', 'Financing - Not Approved/Score Too Low', "Financing - Doesn't Want Debt", "Money - Can't Afford", "Money - More Than Willing to Pay", "Money - Partner/Family Doesn't Approve", "Fear of Not Getting Result", "Comparison Shopping", "Timing Not Right", "Not Interested/Not a Fit", "Unresponsive", "Joined other program"]);
    const [selectedCloser, setSelectedCloser] = useState(null);
    const [closers, setClosers] = useState([])

    const months = [
        { name: 'January', id: 1 },
        { name: 'February', id: 2 },
        { name: 'March', id: 3 },
        { name: 'April', id: 4 },
        { name: 'May', id: 5 },
        { name: 'June', id: 6 },
        { name: 'July', id: 7 },
        { name: 'August', id: 8 },
        { name: 'September', id: 9 },
        { name: 'October', id: 10 },
        { name: 'November', id: 11 },
        { name: 'December', id: 12 }
    ];

   // Function to get the current month object
    const getCurrentMonth = () => {
        const currentMonthIndex = new Date().getMonth(); // getMonth returns 0 for Jan, 1 for Feb, etc.
        return months[currentMonthIndex];
    };
    const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());

    const getSeverity = (status) => {
        switch (status) {
            case 'No':
                return 'danger';

            case 'Yes':
                return 'success';
            
            case 'Organic':
                return 'success';

            case 'Paid':
                return 'danger';

            case 'No Showed':
                return 'danger';

            case 'Showed':
                return 'success';

            case 'Rescheduled':
                return 'warning';
            
            case 'Upcoming':
                return 'info';

            case 'Cancelled':
                return 'contrast';

            case 'New':
                return 'secondary';

            default:
                return 'info'

        }
    };
    
    useEffect(()=>{
        initFilters();
    }, [])

    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
      
        return `${year}-${month}-${day}`;
    }

    function getBrowserTimezone() {
        // Create a date object with an arbitrary date (use UTC to avoid DST changes affecting the timezone offset)
        const date = new Date();
        
        // Get the timezone offset in minutes from UTC for the current date
        const timezoneOffset = date.getTimezoneOffset();
        
        // Convert the offset to hours and minutes for UTC- format (e.g., UTC-04:00)
        const offsetHours = Math.abs(Math.floor(timezoneOffset / 60));
        const offsetMinutes = Math.abs(timezoneOffset % 60);
        const offsetSign = timezoneOffset > 0 ? '-' : '+';
        
        // Construct the timezone string in the format UTC±HH:MM (e.g., UTC-04:00)
        const timezoneString = `UTC${offsetSign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
        
        // Get the timezone name in the 'US/Central' format using Intl.DateTimeFormat
        const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
        return timezoneName;
    }

    useEffect(()=>{
        setStatsLoading(true)
        const browserTimezone = getBrowserTimezone();
        let stats_url
        if (selectedCloser){
            stats_url = `${getStatsUrl}/${locationId}/${userEmail}?timezone=${browserTimezone}&month=${selectedMonth.id}&userId=${selectedCloser.user_id}`
        }
        else{
            stats_url = `${getStatsUrl}/${locationId}/${userEmail}?timezone=${browserTimezone}&month=${selectedMonth.id}&userId=`
        }
        axios.get(stats_url, {
            headers: {'Content-Type': 'application/json'},
        })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setStats(response.data)
                    setStatsLoading(false);
                }
                })
            .catch((error)=>{
                setStats(null)
                
            })
    }, [selectedMonth, selectedCloser, userEmail, locationId])

    useEffect(()=>{
        console.log({dates});
        if(dates){

            const areBothDatesNotNull = dates.every(date => date !== null);
            if (areBothDatesNotNull){
                const browserTimezone = getBrowserTimezone();
                const dateString1 = formatDate(dates[0]);
                const dateString2 = formatDate(dates[1]);
                setLoading(true)
                axios.get(`${allAppointmentsUrl}/${locationId}/${userEmail}?timezone=${browserTimezone}&startDate=${dateString1}&endDate=${dateString2}`, {
                    headers: {'Content-Type': 'application/json'},
                })
                    .then((response) => {
                        if (response.status === 200) {
                            console.log(response.data)
                            setCustomers(response.data.appointments);
                            if (!Array.isArray(response.data.users)) {
                                setSelectedCloser(response.data.users)
                                setClosers([response.data.users])
                            }
                            else{
                                setClosers(response.data.users)
                            }
                            setLoading(false);
                        }
                        })
                    .catch((error)=>{
                        setCustomers(null)
                        
                    })

            }
        }
        
    }, [dates, locationId, userEmail])

    useEffect(()=>{
        setLoading(true)
        const browserTimezone = getBrowserTimezone();
        axios.get(`${allAppointmentsUrl}/${locationId}/${userEmail}?timezone=${browserTimezone}`, {
            headers: {'Content-Type': 'application/json'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setCustomers(response.data.appointments);
                    if (!Array.isArray(response.data.users)) {
                        setSelectedCloser(response.data.users)
                        setClosers([response.data.users])
                    }
                    else{
                        setClosers(response.data.users)
                    }
                    setLoading(false);
                  }
                })
            .catch((error)=>{
                setCustomers(null)
                
            })
    },[isMounted, userEmail, locationId])



    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            full_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            closer: { value: null, matchMode: FilterMatchMode.IN },
            lead_source: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            source_type: { value: null, matchMode: FilterMatchMode.EQUALS },
            status: { value: null, matchMode: FilterMatchMode.EQUALS },
            appointment_status: { value: null, matchMode: FilterMatchMode.EQUALS },
            central_objection: { value: null, matchMode: FilterMatchMode.EQUALS },
            
        });
        setGlobalFilterValue('');
    };

    const renderHeader = () => {
        return (
            <div className="header-flex-container">
                <div className="flex justify-content-between header-flex">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                    </span>
                    <Button type="button" icon="pi pi-filter-slash" outlined onClick={clearFilter} />
                </div>

                <Calendar value={dates} dateFormat="dd/mm/yy" placeholder="Call Date Range" onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection showIcon showButtonBar onClearButtonClick={() =>setIsMounted(!isMounted)} />
            </div>
        );
    };

    const renderStatHeader = () => {
        return (
            <div className="flex justify-content-between header-flex">
                <Dropdown style={{fontWeight:'bold'}} value={selectedCloser} onChange={(e) => setSelectedCloser(e.value)} options={closers} optionLabel="name" 
                    placeholder="Select a Closer" filter className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} />
                
                <Dropdown style={{fontWeight:'bold'}} value={selectedMonth} onChange={(e) => setSelectedMonth(e.value)} options={months} optionLabel="name" 
                    placeholder="Select a Month" className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} />
            </div>

        );
    };

    const redirectBody = (rowData) => {

        return(
            <div className="flex justify-content-center">
                <Button className="redirect-button" icon="pi pi-external-link" onClick={() => handleRedirect(rowData.location_id, rowData.contact_id)}/>
            </div>
        )
    }
    

    const handleRedirect = (locationId, contactId) => {
        const url = `https://app.motivationalmoguls.io/v2/location/${locationId}/contacts/detail/${contactId}`;
        window.open(url, '_blank');
      };
    

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        return <div >{rowIndex}</div>;
      };

    const recordingBodyTemplate = (rowData) => {
        if (rowData.call_recording_link && rowData.call_recording_link !== 'N/A'){
            return(
                <div className='status flex-copy'>
                <a href={rowData.call_recording_link} target="_blank" rel="noopener noreferrer">{rowData.call_recording_link}</a>
                </div>
            )
        }
        else{
            return(
                <div className='status flex-copy'>
                <span>N/A</span>
                </div>
            )
        }
        
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.status} severity={getSeverity(rowData.status)} />;
    }

    const appointmentStatusBodyTemplate = (rowData) => {
        
        if (rowData.appointment_status === 'invalid') {
            return <Tag value={rowData.appointment_status} style={{ background: 'grey', color: 'white' }} />;
        } else {
            return <Tag value={rowData.appointment_status} severity={getSeverity(rowData.appointment_status)} />;
        }
    };

    const centralObjectionBodyTemplate = (rowData) => {
        
        return <Tag value={rowData.central_objection} style={{ background: 'grey', color: 'white' }} />;
        
    };

    const sourceBodyTemplate = (rowData) => {
        
        return <Tag value={rowData.source_type} severity={getSeverity(rowData.source_type)} />;
    };

    const sourceItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const closerRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={closers}
                filter
                // itemTemplate={representativesItemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                // onChange={(e) => console.log(e.value[0].name)}
                optionLabel="name"
                placeholder="Filter"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '1rem', background: 'linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%)', color:'black', borderColor: "#ffffff" }}
            />
        );
    };

    const sourceRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={sources} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={sourceItemTemplate} placeholder="Filter" className="p-column-filter" showClear style={{ minWidth: '7rem' }} />
        );
    };

    const statusItemTemplate = (option) => {
        
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const appointmentStatusItemTemplate = (option) => {

        if (option === 'invalid') {
            return <Tag value={option} style={{ background: 'grey', color: 'white' }} />;
        } else {
            return <Tag value={option} severity={getSeverity(option)} />;
        }
        
    };

    const centralObjectionItemTemplate = (option) => {

        return <Tag value={option} style={{ background: 'grey', color: 'white' }} />;
        
    };

    const statusRowFilterTemplate = (options) => {
        
        return (
            <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Filter" className="p-column-filter" showClear style={{ minWidth: '7rem' }} />
        );
    };

    const appointmentStatusRowFilterTemplate = (options) => {
        
        return (
            <Dropdown value={options.value} options={appointmentStatuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={appointmentStatusItemTemplate} placeholder="Filter" className="p-column-filter" showClear style={{ minWidth: '7rem' }} />
        );
    };

    const centralObjectionRowFilterTemplate = (options) => {
        
        return (
            <Dropdown value={options.value} options={centralObjections} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={centralObjectionItemTemplate} placeholder="Filter" className="p-column-filter" showClear style={{ minWidth: '7rem' }} />
        );
    };

    const onRowEditComplete = (e) => {
        let _customers = [...customers];
        let { newData, index } = e;
        console.log({newData})
        const data = {
            newData,
            index
        }
        axios.post(updateAppointmentsUrl, data,{
            headers: {'Content-Type': 'application/json'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    _customers[response.data.index] = response.data.newData;
                    setCustomers(_customers);
                    toast.current.show({severity:'success', summary: 'Success', detail:'Details updated', life: 3000});
                  }
                })
            .catch((error)=>{
                console.log(error)
                toast.current.show({severity:'error', summary: 'Error', detail:'Failed to update', life: 3000});
                
            })
    };

    const statusEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={statuses}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
                itemTemplate={(option) => {
                    return <Tag value={option} severity={getSeverity(option)}></Tag>;
                }}
            />
        );
    };

    const appointmentStatusEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={appointmentStatuses}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
                itemTemplate={(option) => {
                    return <Tag value={option} severity={getSeverity(option)}></Tag>;
                }}
            />
        );
    };

    const centralObjectionEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={centralObjections}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select an option"
                itemTemplate={(option) => {
                    return <Tag value={option} style={{ background: 'grey', color: 'white' }}></Tag>;
                }}
            />
        );
    };

    const sourceEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={sources}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Source"
                itemTemplate={(option) => {
                    return <Tag value={option} severity={getSeverity(option)}></Tag>;
                }}
            />
        );
    };

    const numberEditor = (options) => {
        // return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
        return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} min={0} max={10000} maxFractionDigits={5} useGrouping={false}/>
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const allowEdit = (rowData) => {
        return rowData.name !== 'Blue Band';
    };

    const header = renderHeader();

    const statHeader = renderStatHeader();

    return (
        <div className="card">
            <Toast ref={toast} />
            <DataTable className="stat-table" value={stats} loading={statsLoading} header={statHeader} showGridlines tableStyle={{ minWidth: '50rem' }}>
                <Column className="stat-first-column" field="date_trailing" header="Date (Trailing)"></Column>
                {/* <Column field="name" header="Closer (Filter)"></Column> */}
                <Column field="total_calls" header="Total # of Calls"></Column>
                <Column field="frequent_lead_source" header="Frequent Lead Source"></Column>
                <Column field="frequent_source_type" header="Frequent Source Type"></Column>
                <Column field="frequent_funnel" header="Frequent Funnel"></Column>
                <Column field="avg_app_score" header="Avg. App Score"></Column>
                <Column field="show_rate" header="Show Rate"></Column>
                <Column field="closed_count" header="Total Closes"></Column>
                <Column field="close_rate" header="Close Rate"></Column>
                <Column field="frequent_objection" header="Frequent Objection"></Column>
            </DataTable>

            <DataTable value={customers} paginator editMode="row" onRowEditComplete={onRowEditComplete} rows={15} rowsPerPageOptions={[15, 20, 50, 100]} scrollable scrollHeight="85vh" showGridlines loading={loading} dataKey="appointment_id" 
                    filters={filters} filterDisplay="row" globalFilterFields={['closer.name', 'utm_medium', 'utm_campaign', 'lead_source', 'full_name', 'source_type', 'status', 'appointment_status', 'email']} header={header}
                    emptyMessage="No Calls found.">
                <Column header="SR NO" style={{ minWidth: '2rem'  }} body={srNoBodyTemplate} frozen alignFrozen="left" />
                {/* <Column field="month" header="Month" sortable style={{ minWidth: '1rem' }} frozen alignFrozen="left" /> */}
                <Column field="date" header="Call Date" sortable style={{ minWidth: '9rem' }} frozen alignFrozen="left" />
                <Column field="closer.name" header="Closer" filterField="closer" sortable showFilterMenu={false} filterMenuStyle={{ minWidth: '1rem' }} style={{ minWidth: '9rem' }} filter filterElement={closerRowFilterTemplate}/>
                <Column field="full_name" header="Prospect Name" sortable style={{ minWidth: '11rem' }}/>
                <Column field="email" header="Prospect Email" sortable style={{ minWidth: '11rem' }}/>
                <Column field="utm_medium" header="UTM Medium" sortable style={{ minWidth: '9rem' }}/>
                <Column field="utm_campaign" header="UTM Campaign" sortable style={{ minWidth: '9rem' }}/>
                <Column field="lead_source" header="Lead Source" sortable style={{ minWidth: '9rem' }}/>
                <Column field="source_type" header="Source Type" editor={(options) => sourceEditor(options)} sortable showFilterMenu={false} filterMenuStyle={{ width: '5rem' }} style={{ minWidth: '5rem' }} body={sourceBodyTemplate} filter filterElement={sourceRowFilterTemplate}/>
                <Column field="funnel" header="Funnel" editor={(options) => textEditor(options)} sortable style={{ minWidth: '9rem' }}/>
                <Column field="app_score" header="App Score" editor={(options) => numberEditor(options)} sortable style={{ minWidth: '9rem' }}/>
                <Column field="status" header="Won Status" editor={(options) => statusEditor(options)} sortable showFilterMenu={false} filterMenuStyle={{ minWidth: '1rem' }} style={{ minWidth: '1rem' }} body={statusBodyTemplate} filter filterElement={statusRowFilterTemplate}/>
                <Column field="appointment_status" header="Appointment Status" editor={(options) => appointmentStatusEditor(options)} sortable showFilterMenu={false} filterMenuStyle={{ minWidth: '1rem' }} style={{ minWidth: '1rem' }} body={appointmentStatusBodyTemplate} filter filterElement={appointmentStatusRowFilterTemplate}/>
                <Column field="date_closed" header="Date Closed" sortable style={{ minWidth: '9rem' }}/>
                <Column field="central_objection" header="Central Objection" editor={(options) => centralObjectionEditor(options)} sortable showFilterMenu={false} filterMenuStyle={{ minWidth: '1rem' }} style={{ minWidth: '15rem' }} body={centralObjectionBodyTemplate} filter filterElement={centralObjectionRowFilterTemplate}/>
                <Column field="call_recording_link" header="Call Recording Link" body={recordingBodyTemplate} editor={(options) => textEditor(options)} sortable style={{ minWidth: '9rem' }}/>
                <Column header="Edit" rowEditor={allowEdit} headerStyle={{ minWidth: '7rem' }} bodyStyle={{ textAlign: 'center' }} frozen alignFrozen="right"/>
                <Column header="Open in CRM" filterMenuStyle={{ width: '14rem' }} style={{ width: '3rem' }} frozen alignFrozen="right" body={redirectBody}/>
                {/* <Column header="Open in CRM" filterMenuStyle={{ width: '14rem' }} style={{ width: '3rem' }} body={redirectBody}/> */}
            </DataTable>
        </div>
    );
}
