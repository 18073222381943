import "primereact/resources/themes/lara-light-indigo/theme.css";    
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import './nav.css'
import React from 'react';
import { Button } from 'primereact/button';
import { useParams } from 'react-router-dom';

const Nav = ({ tab, changeTab }) => {

  const {userEmail} = useParams()
  const validEmails = ['support@authorityentrepreneurs.com', 'admin@courtneylsanders.com', 'web@courtneylsanders.com', 'courtney@courtneylsanders.com', 'hayley@courtneylsanders.com', 'mitzi@courtneylsanders.com', 'deanna@courtneylsanders.com', 'cris@courtneylsanders.com', 'tanya@courtneylsanders.com'];
  const normalizedEmail = userEmail?.toLowerCase();
  const isValidEmail = validEmails?.some(email => email.toLowerCase() === normalizedEmail);

  if(!isValidEmail){
    return (
      <div className="flex justify-content-between header-flex" style={{margin:'1rem'}}>
          <Button className={tab === 'call' ? "nav-button-active" : "nav-button"} type="button" label="Calls Booked" onClick={() => changeTab('call')}/>
          <Button className={tab === 'sale' ? "nav-button-active" : "nav-button"} type="button" label="New Sales" onClick={() => changeTab('sale')}/>
      </div>
    );
  }
  else{
    return (
      <div className="flex justify-content-between header-flex" style={{margin:'1rem'}}>
          <Button className={tab === 'call' ? "nav-button-active" : "nav-button"} type="button" label="Calls Booked" onClick={() => changeTab('call')}/>
          <Button className={tab === 'sale' ? "nav-button-active" : "nav-button"} type="button" label="New Sales" onClick={() => changeTab('sale')}/>
          <Button className={tab === 'commission' ? "nav-button-active" : "nav-button"} type="button" label="Commissions" onClick={() => changeTab('commission')}/>
      </div>
    );
  }
  
};

export default Nav;