export const baseUrl = "https://trackerapi.clsand.co/api";
// export const baseUrl = "http://127.0.0.1:8000";
export const allAppointmentsUrl = "/get_appointments";
export const updateAppointmentsUrl = "/update_appointment";
export const getStatsUrl = "/get_stats";
export const allSalesUrl = "/get_sales";
export const createSaleUrl = "/create_sale";
export const updateSaleUrl = "/update_sale";
export const deleteSalesUrl = "/delete_sales";
export const getSaleStatsUrl = "/get_sale_stats";
export const allCommissionsUrl = "/get_commissions";
export const createCommissionUrl = "/create_commission";
export const updateCommissionUrl = "/update_commission";
export const deleteCommissionsUrl = "/delete_commissions";
export const getCommissionStatsUrl = "/get_commission_stats";