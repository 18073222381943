import "primereact/resources/themes/lara-light-indigo/theme.css";    
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import React, { useState, useEffect,useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import axios from '../../Utils/axios'
import { allCommissionsUrl, createCommissionUrl, updateCommissionUrl, deleteCommissionsUrl, getCommissionStatsUrl } from "../../Utils/Urls";
import { useParams } from 'react-router-dom';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from "primereact/inputtextarea";

export default function CommissionsTable() {
    let emptyProduct = {
        "id": null,
        "date_of_payment": "",
        "date_of_sales_call": "",
        "closer": null,
        "contact_id": "",
        "location_id": "",
        "location_name": "",
        "full_name": "",
        "first_name": "",
        "last_name": "",
        "phone": "",
        "email": "",
        "notes": "",
        "program_purchased": "",
        "payment_type": "",
        "funding_type": "",
        "funding_percentage": null,
        "program_price": null,
        "amount_paid": null,
        "balance_remaining": null,
        "sales_person_id": "",
        "sales_person": "",
        "days_to_close": null,
        "commission": null,
        "date_paid_commission": ""
    }
    const product = useState(emptyProduct);
    const {userEmail} = useParams()
    const {locationId} = useParams()
    const [customers, setCustomers] = useState(null);
    const [stats, setStats] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(true);
    const [statsLoading, setStatsLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [isMounted, setIsMounted] = useState(false)
    const toast = useRef(null);
    const [dates, setDates] = useState(null);
    const [clientName, setClientName] = useState(null);
    const [clientEmail, setClientEmail] = useState(null);
    const [notes, setNotes] = useState(null);
    const [programPurchased, setProgramsPurchased] = useState(null);
    const [paymentType, setPaymentType] = useState(null);
    const [fundingType, setFundingType] = useState(null);
    const [programPrice, setProgramPrice] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);
    const [selectedCloser, setSelectedCloser] = useState(null);
    const [selectedFormCloser, setSelectedFormCloser] = useState(null);
    const [transactionTypes] = useState(['New Close', 'Balance Payment', 'Third-Party Payment', 'A/R Payment']);
    const [programsPurchased] = useState(['Both', 'CLS Certification', 'CLS Marketing', 'Existing Client Payment']);
    const [paymentTypes] = useState(['PIF', 'Funding', 'Payment Plan']);
    const [fundingSources] = useState(['Wire/ACH', 'Credit Card', 'Klarna/Affirm', 'Eaze', 'Climb', 'Elective', 'SF - Tier 1', 'SF - Tier 2', 'SF - Tier 3', 'SF - Tier 4', 'SF - Tier 5']);
    const [closers, setClosers] = useState([])
    const [formVisible, setFormVisible] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [selectedCloseRateTier, setSelectedCloseRateTier] = useState(null);
    const [closeRateTiers] = useState([0.125, 0.15, 0.175, 0.2, 0.225, 0.25]);

    const months = [
        { name: 'January', id: 1 },
        { name: 'February', id: 2 },
        { name: 'March', id: 3 },
        { name: 'April', id: 4 },
        { name: 'May', id: 5 },
        { name: 'June', id: 6 },
        { name: 'July', id: 7 },
        { name: 'August', id: 8 },
        { name: 'September', id: 9 },
        { name: 'October', id: 10 },
        { name: 'November', id: 11 },
        { name: 'December', id: 12 }
    ];

    // Function to get the current month object
    const getCurrentDate = () => {
        const currentDate = new Date();
        return currentDate;
    };

    const [dateOfPayment, setDateOfPayment] = useState(null);
    const [dateOfSalesCall, setDateOfSalesCall] = useState(null);
    const [datePaidCommission, setDatePaidCommission] = useState(null);

    // Function to get the current month object
    const getCurrentMonth = () => {
        const currentMonthIndex = new Date().getMonth(); // getMonth returns 0 for Jan, 1 for Feb, etc.
        return months[currentMonthIndex];
    };
    const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());

    const getBackgound = (status) => {
        switch (status) {

            case 'Third-Party Payment':
                return 'rgb(255 198 47)';

            case 'New Close':
                return '#11734b';

            case 'Balance Payment':
                return 'rgb(127 161 95)';

            case 'A/R Payment':
                return 'rgb(255 141 126)';
            
            case 'CLS Marketing':
                return 'rgb(82 189 255)';

            case 'CLS Certification':
                return 'rgb(201 98 255)';

            case 'Both':
                return '#5a3286';

            case 'Existing Client Payment':
                return 'rgb(127 161 95)';

            case 'PIF':
                return 'rgb(127 161 95)';

            case 'Funding':
                return 'rgb(255 198 47)';

            case 'Payment Plan':
                return 'rgb(255 141 126)';

            case 'Wire/ACH':
                return '#11734b';
            
            case 'Credit Card':
                return '#11734b';

            case 'Klarna/Affirm':
                return '#11734b';

            case 'Eaze':
                return 'rgb(127 161 95)';

            case 'Elective':
                return 'rgb(255 198 47)';
            
            case 'Climb':
                return 'rgb(255 141 126)';

            case 0.125:
                return 'red';
            
            case 0.15:
                return '#ff5e00';

            case 0.175:
                return 'rgb(255 141 126)';

            case 0.2:
                return 'rgb(255 198 47)';

            case 0.225:
                return 'rgb(127 161 95)';
            
            case 0.25:
                return '#11734b';


            default:
                return 'rgb(255 139 124)';

        }
    };

    useEffect(()=>{
        setSelectedCloseRateTier(selectedCloser?.close_rate_tier);
    }, [selectedCloser])
    
    useEffect(()=>{
        initFilters();
    }, [])

    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
      
        return `${year}-${month}-${day}`;
    }

    function getBrowserTimezone() {
        // Create a date object with an arbitrary date (use UTC to avoid DST changes affecting the timezone offset)
        const date = new Date();
        
        // Get the timezone offset in minutes from UTC for the current date
        const timezoneOffset = date.getTimezoneOffset();
        
        // Convert the offset to hours and minutes for UTC- format (e.g., UTC-04:00)
        const offsetHours = Math.abs(Math.floor(timezoneOffset / 60));
        const offsetMinutes = Math.abs(timezoneOffset % 60);
        const offsetSign = timezoneOffset > 0 ? '-' : '+';
        
        // Construct the timezone string in the format UTC±HH:MM (e.g., UTC-04:00)
        const timezoneString = `UTC${offsetSign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
        
        // Get the timezone name in the 'US/Central' format using Intl.DateTimeFormat
        const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
        return timezoneName;
    }

    useEffect(()=>{
        setStatsLoading(true)
        const browserTimezone = getBrowserTimezone();
        let stats_url
        if (selectedCloser && selectedCloseRateTier){
            stats_url = `${getCommissionStatsUrl}/${locationId}/${userEmail}?timezone=${browserTimezone}&month=${selectedMonth.id}&userId=${selectedCloser.user_id}&closeRate=${selectedCloseRateTier}`
        
            axios.get(stats_url, {
                headers: {'Content-Type': 'application/json'},
            })
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        setCustomers(response.data.commissions)
                        setStats(response.data.stat_data)
                        setStatsLoading(false);
                    }
                    })
                .catch((error)=>{
                    setStats(null)
                    
                })
            }
    }, [selectedMonth, selectedCloser, userEmail, locationId, selectedCloseRateTier])


    useEffect(()=>{
        console.log({dates});
        if(dates){

            const areBothDatesNotNull = dates.every(date => date !== null);
            if (areBothDatesNotNull){
    
                const dateString1 = formatDate(dates[0]);
                const dateString2 = formatDate(dates[1]);
                setLoading(true)
                axios.get(`${allCommissionsUrl}/${locationId}/${userEmail}?startDate=${dateString1}&endDate=${dateString2}`, {
                    headers: {'Content-Type': 'application/json'},
                })
                    .then((response) => {
                        if (response.status === 200) {
                            console.log(response.data)
                            setCustomers(response.data.commissions);
                            if (!Array.isArray(response.data.users)) {
                                setSelectedCloser(response.data.users)
                                setClosers([response.data.users])
                            }
                            else{
                                setSelectedCloser(response.data.users[0])
                                setClosers(response.data.users)
                            }
                            setLoading(false);
                        }
                        })
                    .catch((error)=>{
                        setCustomers(null)
                        
                    })

            }
        }
        
    }, [dates, locationId, userEmail])

    useEffect(()=>{
        setLoading(true)
        axios.get(`${allCommissionsUrl}/${locationId}/${userEmail}`, {
            headers: {'Content-Type': 'application/json'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setCustomers(response.data.commissions);
                    if (!Array.isArray(response.data.users)) {
                        setSelectedCloser(response.data.users)
                        setClosers([response.data.users])
                    }
                    else{
                        setSelectedCloser(response.data.users[0])
                        setClosers(response.data.users)
                    }
                    setLoading(false);
                  }
                })
            .catch((error)=>{
                setCustomers(null)
                
            })
    },[isMounted, userEmail, locationId])



    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            full_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            notes: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            closer: { value: null, matchMode: FilterMatchMode.IN },
            program_purchased: { value: null, matchMode: FilterMatchMode.EQUALS },
            payment_type: { value: null, matchMode: FilterMatchMode.EQUALS },
            funding_type: { value: null, matchMode: FilterMatchMode.EQUALS },
        });
        setGlobalFilterValue('');
    };

    const renderHeader = () => {
        return (
            <div className="header-flex-container">
                <div className="flex justify-content-between header-flex">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                    </span>
                    <Button type="button" icon="pi pi-filter-slash" outlined onClick={clearFilter} />
                </div>

                <div className="flex justify-content-between header-flex">
                    <Calendar value={dates} dateFormat="dd/mm/yy" placeholder="Sale Date Range" onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection showIcon showButtonBar onClearButtonClick={() =>setIsMounted(!isMounted)} />
                </div>
            </div>
        );
    };

    const renderStatHeader = () => {
        return (
            <div className="flex justify-content-between header-flex">
                <div className="flex justify-content-between header-flex">
                <Dropdown style={{fontWeight:'bold'}} value={selectedCloser} onChange={(e) => setSelectedCloser(e.value)} options={closers} optionLabel="name" 
                    placeholder="Select a Closer" filter className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} />

                <Dropdown style={{width:'100%', borderColor:'#C4B069'}} value={selectedCloseRateTier} options={closeRateTiers} onChange={(e) => setSelectedCloseRateTier(e.value)} placeholder="Current Close Rate Tier"
                    itemTemplate={(option) => {
                    return <Tag value={option} style={{ background: `${getBackgound(option)}` }}></Tag>;
                    }}
                />
                </div>
                
                <div>
                    <Dropdown style={{fontWeight:'bold'}} value={selectedMonth} onChange={(e) => setSelectedMonth(e.value)} options={months} optionLabel="name" 
                    placeholder="Select a Month" className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} />
                </div>
            </div>

        );
    };

    const redirectBody = (rowData) => {

        return(
            <div className="flex justify-content-center">
                <Button className="redirect-button" icon="pi pi-external-link" onClick={() => handleRedirect(rowData.location_id, rowData.contact_id)}/>
            </div>
        )
    }
    

    const handleRedirect = (locationId, contactId) => {
        const url = `https://app.motivationalmoguls.io/v2/location/${locationId}/contacts/detail/${contactId}`;
        window.open(url, '_blank');
      };
    

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        return <div >{rowIndex}</div>;
      };

    const amountBodyTemplate = (option) => {
        return `$${option}`
    }

    const percentageBodyTemplate = (option) => {
        return `${option}%`
    }

    const programPurchasedBodyTemplate = (rowData) => {
        
        return <Tag value={rowData.program_purchased} style={{ background: `${getBackgound(rowData.program_purchased)}` }} />;
    };

    const paymentTypeBodyTemplate = (rowData) => {
        
        return <Tag value={rowData.payment_type} style={{ background: `${getBackgound(rowData.payment_type)}` }} />;
    };

    const fundingSourceBodyTemplate = (rowData) => {
        
        return <Tag value={rowData.funding_type} style={{ background: `${getBackgound(rowData.funding_type)}` }} />;
    };

    const programPurchasedItemTemplate = (option) => {
        return <Tag value={option} style={{ background: `${getBackgound(option)}` }} />;
    };

    const paymentTypeItemTemplate = (option) => {
        return <Tag value={option} style={{ background: `${getBackgound(option)}` }} />;
    };

    const fundingSourceItemTemplate = (option) => {
        return <Tag value={option} style={{ background: `${getBackgound(option)}` }} />;
    };

    const closerRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={closers}
                filter
                // itemTemplate={representativesItemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                // onChange={(e) => console.log(e.value[0].name)}
                optionLabel="name"
                placeholder="Filter"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '1rem', background: 'linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%)', color:'black', borderColor: "#ffffff" }}
            />
        );
    };

    const programPurchasedRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={programsPurchased} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={programPurchasedItemTemplate} placeholder="Filter" className="p-column-filter" showClear style={{ minWidth: '7rem' }} />
        );
    };

    const paymentTypeRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={paymentTypes} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={paymentTypeItemTemplate} placeholder="Filter" className="p-column-filter" showClear style={{ minWidth: '7rem' }} />
        );
    };

    const fundingSourceRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={fundingSources} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={fundingSourceItemTemplate} placeholder="Filter" className="p-column-filter" showClear style={{ minWidth: '7rem' }} />
        );
    };

    const onRowEditComplete = (e) => {
        setLoading(true)
        let _customers = [...customers];
        let { newData, index } = e;
        const data = {
            newData,
            index
        }

        axios.post(updateCommissionUrl, data,{
            headers: {'Content-Type': 'application/json'},
          })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false)
                    console.log(response.data)
                    _customers[response.data.index] = response.data.newData;
                    setCustomers(_customers);
                    toast.current.show({severity:'success', summary: 'Success', detail:'Details updated', life: 3000});
                  }
                })
            .catch((error)=>{
                setLoading(false)
                console.log(error)
                toast.current.show({severity:'error', summary: 'Error', detail:'Failed to update', life: 3000});
                
            })
    };

    const programPurchasedEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={programsPurchased}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Program"
                itemTemplate={(option) => {
                    return <Tag value={option} style={{ background: `${getBackgound(option)}` }}></Tag>;
                }}
            />
        );
    };

    const paymentTypeEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={paymentTypes}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Payment"
                itemTemplate={(option) => {
                    return <Tag value={option} style={{ background: `${getBackgound(option)}` }}></Tag>;
                }}
            />
        );
    };

    const fundingSourceEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={fundingSources}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Funding Source"
                itemTemplate={(option) => {
                    return <Tag value={option} style={{ background: `${getBackgound(option)}` }}></Tag>;
                }}
            />
        );
    };

    const numberEditor = (options) => {
        // return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
        return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} min={0} maxFractionDigits={5} useGrouping={false}/>
    };

    const allowEdit = (rowData) => {
        return rowData.name !== 'Blue Band';
    };

    const onCloseDialog = () => {
        setClientName(null);
        setClientEmail(null);
        setNotes(null)
        setProgramsPurchased(null);
        setPaymentType(null);
        setFundingType(null);
        setProgramPrice(0);
        setAmountPaid(0);
        setSelectedFormCloser(null);
        setDateOfPayment(null);
        setDateOfSalesCall(null);
        setDatePaidCommission(null);
        setFormVisible(false);
    }

    const validateEmail = (email)=> {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }

    const handleFormSubmit = (e) => {
        if(!selectedFormCloser){
            toast.current.show({severity:'error', summary: 'Error', detail:'Please Select a closer', life: 3000});
        }
        else if(!clientName || (clientName && !clientName.trim())){
            toast.current.show({severity:'error', summary: 'Error', detail:'Client Name is required', life: 3000});
        }
        else if(!clientEmail || (clientEmail && !clientEmail.trim())){
            toast.current.show({severity:'error', summary: 'Error', detail:'Client Email is required', life: 3000});
        }
        else if (!validateEmail(clientEmail.trim())) {
            toast.current.show({severity: 'error', summary: 'Error', detail: 'Please enter a valid email address', life: 3000});
        }
        else if(!dateOfPayment){
            toast.current.show({severity:'error', summary: 'Error', detail:'Please Select a Date of Payment', life: 3000});
        }
        else if(!dateOfSalesCall){
            toast.current.show({severity:'error', summary: 'Error', detail:'Please Select a Date of Sales Call', life: 3000});
        }
        // else if(!datePaidCommission){
        //     toast.current.show({severity:'error', summary: 'Error', detail:'Please Select a Date Paid Commission', life: 3000});
        // }
        else{
            setSubmitLoading(true)
            const data = {
                "clientName": clientName.trim(),
                "clientEmail": clientEmail.trim(),
                notes,
                "dateOfPayment": formatDate(dateOfPayment),
                programPurchased,
                paymentType,
                fundingType,
                programPrice,
                amountPaid,
                "selectedCloser": selectedFormCloser,
                "dateOfSalesCall": formatDate(dateOfSalesCall),
                "datePaidCommission": datePaidCommission ? formatDate(datePaidCommission) : null,
                locationId
            }
    
            axios.post(createCommissionUrl, data,{
            headers: {'Content-Type': 'application/json'},
            })
            .then((response) => {
                if (response.status === 201) {
                    console.log(response.data.commission)
                    setSubmitLoading(false)
                    const newCustomer = response.data.commission
                    const updatedCustomers = [newCustomer, ...customers];
                    setCustomers(updatedCustomers)
                    onCloseDialog()
                    toast.current.show({severity:'success', summary: 'Success', detail:'Commission Added', life: 3000});
                    }
                })
            .catch((error)=>{
                setSubmitLoading(false)
                console.log(error)
                toast.current.show({severity:'error', summary: 'Error', detail:'Failed to add new commission', life: 3000});
            })
        }  
    }

    const footerContent = (
        <div>
            <Button className="nav-button" icon="pi pi-check" loading={submitLoading} type="button" label="Submit" onClick={handleFormSubmit}/>
        </div>
    );

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button className="nav-button-active" label="Delete" icon="pi pi-trash" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} />
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button className="nav-button-active" icon="pi pi-plus" type="button" label="Add Sale" onClick={() => setFormVisible(true)}/>
            </div>
        );
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const deleteSelectedProducts = () => {
        setSubmitLoading(true)
        const ids = selectedProducts.map(customer => customer.id);
        const data = {
            ids
        }
        let _products = customers.filter((val) => !selectedProducts.includes(val));

        axios.post(deleteCommissionsUrl, data,{
            headers: {'Content-Type': 'application/json'},
          })
            .then((response) => {
                if (response.status === 200) {
                    setSubmitLoading(false)
                    setCustomers(_products);
                    setDeleteProductsDialog(false);
                    setSelectedProducts(null);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Deleted Successfully', life: 3000 });
                  }
                })
            .catch((error)=>{
                setSubmitLoading(false)
                console.log(error)
                toast.current.show({severity:'error', summary: 'Error', detail:'Failed to delete', life: 3000});
                
            })
    };

    const deleteProductsDialogFooter = (
        <React.Fragment>
            {/* <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteProductsDialog} /> */}
            <Button className="nav-button" label="Yes" icon="pi pi-check" loading={submitLoading} onClick={deleteSelectedProducts} />
        </React.Fragment>
    );

    const header = renderHeader();

    const statHeader = renderStatHeader();

    return (
        <div className="card">
            <Toast ref={toast} />
            <DataTable className="stat-table" loading={statsLoading} value={stats} header={statHeader} showGridlines tableStyle={{ minWidth: '50rem' }}>
                <Column className="stat-first-column" field="date_trailing"></Column>
                <Column field="total_sales_by_closer" header="Total Sales By Closer" body={(options) => amountBodyTemplate(options.total_sales_by_closer)}></Column>
                <Column field="average_days_to_close" header="Average DTC By Closer"></Column>
                <Column field="total_by_closer" header="Total By Closer" body={(options) => amountBodyTemplate(options.total_by_closer)}></Column>
            </DataTable>

            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}/>
            <DataTable value={customers} paginator editMode="row" onRowEditComplete={onRowEditComplete} rows={15} rowsPerPageOptions={[15, 20, 50, 100]} scrollable scrollHeight="85vh" showGridlines loading={loading} 
                    selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}dataKey="id" 
                    filters={filters} filterDisplay="row" globalFilterFields={['closer.name', 'full_name', 'email', 'notes']} header={header}
                    emptyMessage="No Commissions found.">
                <Column selectionMode="multiple" exportable={false} frozen alignFrozen="left"/>
                <Column header="SR NO" style={{ minWidth: '2rem'  }} body={srNoBodyTemplate} frozen alignFrozen="left" />
                <Column field="full_name" header="Client Sold" sortable style={{ minWidth: '11rem' }}/>
                <Column field="email" header="Client Email" sortable style={{ minWidth: '11rem' }}/>
                <Column field="notes" header="Notes" sortable style={{ minWidth: '11rem' }}/>
                <Column field="date_of_payment" header="Date of Payment" sortable style={{ minWidth: '9rem' }} />
                <Column field="program_purchased" header="Program Puchased" editor={(options) => programPurchasedEditor(options)} sortable showFilterMenu={false} filterMenuStyle={{ width: '5rem' }} style={{ minWidth: '13rem' }} body={programPurchasedBodyTemplate} filter filterElement={programPurchasedRowFilterTemplate}/>
                <Column field="payment_type" header="Payment Type" editor={(options) => paymentTypeEditor(options)} sortable showFilterMenu={false} filterMenuStyle={{ width: '5rem' }} style={{ minWidth: '9rem' }} body={paymentTypeBodyTemplate} filter filterElement={paymentTypeRowFilterTemplate}/>
                <Column field="funding_type" header="Funding Type" editor={(options) => fundingSourceEditor(options)} sortable showFilterMenu={false} filterMenuStyle={{ width: '5rem' }} style={{ minWidth: '9rem' }} body={fundingSourceBodyTemplate} filter filterElement={fundingSourceRowFilterTemplate}/>
                <Column field="funding_percentage" header="Funding Percentage" sortable style={{ minWidth: '9rem' }} body={(options) => percentageBodyTemplate(options.funding_percentage)}/>
                <Column field="program_price" header="Program Price" sortable style={{ minWidth: '9rem' }} editor={(options) => numberEditor(options)} body={(options) => amountBodyTemplate(options.program_price)}/>
                <Column field="amount_paid" header="Amount Paid" sortable style={{ minWidth: '9rem' }} editor={(options) => numberEditor(options)} body={(options) => amountBodyTemplate(options.amount_paid)}/>
                <Column field="balance_remaining" header="Balance Remaining" sortable style={{ minWidth: '9rem' }} body={(options) => amountBodyTemplate(options.balance_remaining)}/>
                <Column field="closer.name" header="Sales Person" filterField="closer" sortable showFilterMenu={false} filterMenuStyle={{ minWidth: '1rem' }} style={{ minWidth: '9rem' }} filter filterElement={closerRowFilterTemplate}/>
                <Column field="date_of_sales_call" header="Date of Sales Call" sortable style={{ minWidth: '9rem' }} />
                <Column field="days_to_close" header="Days to Close" sortable style={{ minWidth: '9rem' }} />
                <Column field="commission" header="Commission" sortable style={{ minWidth: '9rem' }} body={(options) => amountBodyTemplate(options.commission)}/>
                <Column field="date_paid_commission" header="Date Paid Commission" sortable style={{ minWidth: '9rem' }} />
                <Column header="Edit" rowEditor={allowEdit} headerStyle={{ minWidth: '7rem' }} bodyStyle={{ textAlign: 'center' }} frozen alignFrozen="right"/>
                <Column header="Open in CRM" filterMenuStyle={{ width: '14rem' }} style={{ width: '3rem' }} frozen alignFrozen="right" body={redirectBody}/>
            </DataTable>

            <Dialog header="New Commission" maximizable visible={formVisible}  onHide={onCloseDialog} footer={footerContent}>
                <p className="form-row">
                    <div className="form-column">
                        <label htmlFor="currency-us" className="font-bold block mb-2">Client Name*</label>
                        <InputText style={{borderColor:'#C4B069'}} value={clientName} onChange={(e)=>setClientName(e.target.value)} placeholder="Client Name" />
                    </div>
                    <div className="form-column">
                        <label htmlFor="currency-us" className="font-bold block mb-2">Client Email*</label>
                        <InputText style={{borderColor:'#C4B069'}} value={clientEmail} onChange={(e)=>setClientEmail(e.target.value)} placeholder="Client Email" />
                    </div>
                </p>
                <p className="form-row">
                    <div className="form-column">
                        <label htmlFor="currency-us" className="font-bold block mb-2">Date of Payment</label>
                        <Calendar value={dateOfPayment} onChange={(e) => setDateOfPayment(e.value)} dateFormat="dd/mm/yy" placeholder="Date"/>
                    </div>
                    <div className="form-column">
                        <label htmlFor="currency-us" className="font-bold block mb-2">Program Purchased</label>
                        <Dropdown style={{width:'100%', borderColor:'#C4B069'}} value={programPurchased} options={programsPurchased} onChange={(e) => setProgramsPurchased(e.value)} placeholder="Program Purchased"
                            itemTemplate={(option) => {
                            return <Tag value={option} style={{ background: `${getBackgound(option)}` }}></Tag>;
                            }}
                        />
                    </div>
                </p>
                <p className="form-row">
                    <div className="form-column">
                        <label htmlFor="currency-us" className="font-bold block mb-2">Payment Type</label>
                        <Dropdown style={{width:'100%', borderColor:'#C4B069'}} value={paymentType} options={paymentTypes} onChange={(e) => setPaymentType(e.value)} placeholder="Payment Type"
                            itemTemplate={(option) => {
                            return <Tag value={option} style={{ background: `${getBackgound(option)}` }}></Tag>;
                            }}
                        />
                    </div>
                    <div className="form-column">
                        <label htmlFor="currency-us" className="font-bold block mb-2">Funding Type</label>
                        <Dropdown style={{width:'100%', borderColor:'#C4B069'}} value={fundingType} options={fundingSources} onChange={(e) => setFundingType(e.value)} placeholder="Funding Type"
                            itemTemplate={(option) => {
                            return <Tag value={option} style={{ background: `${getBackgound(option)}` }}></Tag>;
                            }}
                        />
                    </div>
                </p>
                <p className="form-row">
                    <div className="form-column">
                        <label htmlFor="currency-us" className="font-bold block mb-2">Program Price</label>
                        <InputNumber style={{borderColor:'#C4B069'}} inputId="currency-us" value={programPrice} onValueChange={(e) => setProgramPrice(e.value)} mode="currency" currency="USD" locale="en-US" placeholder="Program Price" />
                    </div>
                    <div className="form-column">
                        <label htmlFor="currency-us" className="font-bold block mb-2">Amount Paid</label>
                        <InputNumber style={{borderColor:'#C4B069'}} inputId="currency-us" value={amountPaid} onValueChange={(e) => setAmountPaid(e.value)} mode="currency" currency="USD" locale="en-US" placeholder="Amount Paid"/>
                    </div>
                </p>
                <p className="form-row">
                    <div className="form-column">
                        <label htmlFor="currency-us" className="font-bold block mb-2">Date Of Sales Call*</label>
                        <Calendar value={dateOfSalesCall} onChange={(e) => setDateOfSalesCall(e.value)} dateFormat="dd/mm/yy" placeholder="Date"/>
                    </div>
                    <div className="form-column">
                        <label htmlFor="currency-us" className="font-bold block mb-2">Date Paid Commission*</label>
                        <Calendar value={datePaidCommission} onChange={(e) => setDatePaidCommission(e.value)} dateFormat="dd/mm/yy" placeholder="Date"/>
                    </div>
                </p>
                <p className="form-row">
                    <div className="form-column">
                        <label htmlFor="currency-us" className="font-bold block mb-2">Sales Person*</label>
                        <Dropdown style={{borderColor:'#C4B069'}} value={selectedFormCloser} onChange={(e) => setSelectedFormCloser(e.value)} options={closers} optionLabel="name" 
                        placeholder="Closer" filter className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} />
                    </div>
                    <div className="form-column">
                        <label htmlFor="currency-us" className="font-bold block mb-2">Notes</label>
                        <InputTextarea autoResize value={notes} onChange={(e) => setNotes(e.target.value)} rows={1} cols={30} />
                    </div>
                </p>
            </Dialog>

            <Dialog visible={deleteProductsDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {product && <span>Are you sure you want to delete the selected commissions?</span>}
                </div>
            </Dialog>
        </div>
    );
}
