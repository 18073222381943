import React, {useState} from 'react';
import AppointmentsTable from '../Components/AppointmentsTable/AppointmentsTable';
import SalesTable from '../Components/SalesTable/SalesTable';
import Nav from '../Components/Nav/Nav';
import CommissionsTable from '../Components/CommissionsTable/CommissionsTable';

const Main = () => {
    const [tab, setTab] = useState('call');

    const changeTab = (value) =>{
        setTab(value)
    }

  return (
    <div className="App">
        <Nav tab={tab} changeTab={changeTab}/>
        {tab === 'call' ? (
            <AppointmentsTable />
        ) : tab === 'sale' ? (
            <SalesTable />
        ) : tab === 'commission' ? (
            <CommissionsTable />
        ) : null}
    </div>
  );
};

export default Main;
